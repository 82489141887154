import { Container } from '@mui/material'
import { Introduction } from './components/Introduction'
import theme from '../../utils/theme'
import { AboutUs } from './components/AboutUs'
import { FAQ } from './components/FAQ'
import { VyshkaMap } from './components/Map'
import { TheyTalk } from './components/TheyTalk'
import { OurTrainers } from './components/OurTrainers'


export const Main = () => {

    return (
        <>
            <Container
                maxWidth={'lg'}
                sx={{
                    mt: 16,
                    [ theme.breakpoints.down('md') ]: {
                        mt: 6,
                    },
                }}
            >
                <Introduction/>
                <AboutUs/>
                <FAQ/>
                <VyshkaMap/>
                <OurTrainers/>
                <TheyTalk/>
            </Container>
        </>
    )
}