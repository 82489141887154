import polygons from '../images/polygons.svg'
import { Box } from '@mui/material'
import React from 'react'


export const BackgroundPolygons = () => {

    return (
        <Box
            position={'absolute'}
            top={0}
            left={0}
            zIndex={-5}
            width={'calc((100vw - 1200px)/2)'}
        >
            <img
                alt={'Фигуры на задний фон'}
                src={polygons}
                width={'100%'}
                height={'100%'}
            />
        </Box>
    )
}