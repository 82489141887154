import React from 'react'
import { Box } from '@mui/material'
import theme from '../utils/theme'


export const RightBackgroundCircle = () => {

    return (
        <Box
            position={'absolute'}
            borderRadius={'50%'}
            zIndex={-1}
            sx={{
                background: 'rgba(0,74,125,0.12)',
                right: '-40vh',
                top: '-1%',
                width: '110vh',
                height: '110vh',
                [ theme.breakpoints.only('md') ]: {
                    right: '-50vw',
                },
                [ theme.breakpoints.only('sm') ]: {
                    right: '-70vw',
                    top: 0,
                    width: '100vh',
                    height: '100vh',
                },
                [ theme.breakpoints.only('xs') ]: {
                    right: '-165vw',
                    top: '2%',
                    width: '100vh',
                    height: '100vh',
                },
            }}
        />
    )
}