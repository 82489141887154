import React from 'react'
// import { hydrate, render } from 'react-dom'
import { Box, ThemeProvider } from '@mui/material'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Main } from './pages/mainPage/Main'
import VyshkaAppBar from './components/AppBar'
import { RightBackgroundCircle } from './components/RightBackgroundCircle'
import { VyshkaFooter } from './components/Footer'

import theme from './utils/theme'
import { BackgroundPolygons } from './components/BackgroundPolygons'
import { Gallery } from './pages/gallery/Gallery'
import * as ReactDOM from 'react-dom'


ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                <RightBackgroundCircle/>
                <BackgroundPolygons/>
                <BrowserRouter>
                    <VyshkaAppBar/>
                    <Routes>
                        <Route path={'/'} element={<Main/>}/>
                        <Route path={'/gallery'} element={<Gallery/>}/>
                    </Routes>
                </BrowserRouter>
                <VyshkaFooter/>
            </Box>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)
