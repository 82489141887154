import React, { useCallback, useEffect, useState } from 'react'
import { Box, Container, Stack, Typography, useMediaQuery } from '@mui/material'
import theme from '../../utils/theme'
import Masonry from '@mui/lab/Masonry'
import { Waypoint } from 'react-waypoint'
import axios from 'axios'
import { PHOTOS_LIST_URL } from '../../utils/constants'
import XMLParser from 'react-xml-parser'


export const Gallery = () => {

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
    const [take, setTake] = useState(6)

    const handleEnterEnd = useCallback(() => {
        setTake(prevState => prevState + 6)
    }, [setTake])

    // FOR PHOTOS FROM Y.CLOUD
    const [photosList, setPhotosList] = useState(null)
    const fetchPhotosList = () => {
        axios
            .get(PHOTOS_LIST_URL)
            .then((res) => {
                let obj = new XMLParser().parseFromString(res.data)
                obj = obj.children.filter((element) => element.name === 'Contents')

                let array = obj
                    .map((e) => e.children[ 0 ].value.replace(/ /ig, '%20'))
                    .filter((e) => (e.indexOf('.jpg') !== -1) || (e.indexOf('.jpeg') !== -1))
                setPhotosList(array)
                console.log(array)
            })
            .catch((e) => console.error(e))
    }

    useEffect(() => {
        fetchPhotosList()
    }, [])

    return (
        <Container
            maxWidth={'lg'}
            sx={{
                mt: 12,
                minHeight: '80vh',
                [ theme.breakpoints.down('md') ]: {
                    mt: 4,
                },
            }}
        >
            <Typography variant={'h2'} component={'h1'}>
                Галерея
            </Typography>
            <Box mt={isMobile ? 0 : 4}>
                {
                    isMobile ?
                        <Stack direction={'column'} alignItems={'center'} pb={4}>
                            {
                                photosList &&
                                photosList
                                    .filter((value, index) => index < take)
                                    .map((image) => {
                                        return (
                                            <img
                                                key={image}
                                                src={PHOTOS_LIST_URL + image}
                                                alt={'student'}
                                                style={{
                                                    width: '90vw',
                                                    height: 'auto',
                                                    marginTop: 16,
                                                }}
                                            />
                                        )
                                    })
                            }
                            <Waypoint onEnter={handleEnterEnd}/>
                        </Stack>
                        :
                        <Box>
                            <Masonry
                                columns={3}
                                spacing={2}
                                defaultColumns={3}
                                defaultSpacing={2}
                            >
                                {
                                    photosList &&
                                    photosList.map((image) => {
                                        return (
                                            <img
                                                key={image}
                                                src={PHOTOS_LIST_URL + image}
                                                loading={'lazy'}
                                                alt={'student'}
                                            />
                                        )
                                    })
                                }
                            </Masonry>
                        </Box>
                }
            </Box>
        </Container>
    )
}