import React from 'react'

// FOR GALLERY IN GALLERY
export const PHOTOS_LIST_URL = 'https://storage.yandexcloud.net/vyska-assets/'
export const GALLERY_URL = PHOTOS_LIST_URL + 'gallery/'

// FOR SCROLLING

export const allRefs = {
    aboutUs: React.createRef(),
    faq: React.createRef(),
    map: React.createRef(),
    reviews: React.createRef(),
    contacts: React.createRef(),
    trainers: React.createRef(),
    gallery: null,
}