import React, { useRef, useState } from 'react'
import { Box, Grid, Slide, Typography, useMediaQuery } from '@mui/material'

import firstPhoto from '../../../images/aboutUs/firstPhoto.jpg'
import secondPhoto from '../../../images/aboutUs/secondPhoto.jpg'
import thirdPhoto from '../../../images/aboutUs/thirdPhoto.jpg'
import { LeftBackgroundCircle } from '../../../components/LeftBackgroundCircle'
import theme from '../../../utils/theme'
import { Waypoint } from 'react-waypoint'
import { allRefs } from '../../../utils/constants'


const Section = ({ photo, title, text, photoFirst = false, sx, imageAlt }) => {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const [hover, setHover] = useState(false)

    return (
        <Grid
            container
            direction={photoFirst ? 'row' : 'row-reverse'}
            alignItems={'center'}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{
                ...sx,
            }}
        >
            <Grid item xs={12} md={6}>
                {isMobile ?
                    <Box
                        height={'23vh'}
                        width={'100%'}
                        borderRadius={1}
                        title={imageAlt}
                        sx={{
                            backgroundImage: `url(${photo})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}
                    />
                    :
                    <Box
                        height={'27.5vh'}
                        width={'80%'}
                        mx={'auto'}
                        borderRadius={1}
                        title={imageAlt}
                        sx={{
                            backgroundImage: `url(${photo})`,
                            backgroundSize: 'cover',
                            backgroundPosition: '60% center',
                            backgroundRepeat: 'no-repeat',
                            boxShadow: hover ? '25px 25px 50px #ebebeb, -25px -25px 50px #ffffff;' : 'none',
                            transform: hover ? 'scale(1.05)' : 'none',
                            transition: 'transform .3s',
                        }}
                    />
                }
            </Grid>
            <Grid item xs={12} md={6}>
                <Box
                    width={'80%'}
                    mx={'auto'}
                    sx={{
                        transform: hover ? 'scale(1.05)' : 'none',
                        transition: 'transform .3s',
                        [ theme.breakpoints.down('md') ]: {
                            my: 2,
                        },
                    }}
                >
                    <Typography variant={'h6'} component={'h3'}>
                        {title}
                    </Typography>
                    <Typography variant={'body1'} component={'p'} mt={1}>
                        {text}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export const AboutUs = () => {

    const [appear, setAppear] = useState([true, false, false])
    const containerRef = useRef(null)

    const handleEnterSection = (index) => {
        setAppear(prevState => prevState.map((value, itemIndex) => {
            return value || itemIndex <= index
        }))
    }

    return (
        <Box
            textAlign={'start'}
            mt={4}
            ref={allRefs.aboutUs}
            position={'relative'}
        >
            <Box ref={containerRef}>
                <Typography variant={'h2'} component={'h2'}>
                    О нас
                </Typography>
                <Waypoint onEnter={() => handleEnterSection(1)}/>
                <Slide
                    direction={'up'}
                    in={appear[ 0 ]}
                    mountOnEnter
                    unmountOnExit
                    container={containerRef.current}
                    {...{ timeout: 1000 }}
                >
                    <Box>
                        <Section
                            photoFirst
                            photo={firstPhoto}
                            title={'Безопасная программа'}
                            text={'Учитывает физиологические и психологические особенности детей, гарантирует постепенное, безопасное и экологически правильное развитие детского организма.'}
                            imageAlt={'Дети, занимающиеся тхэквондо'}
                            sx={{
                                mt: 4,
                            }}
                        />
                    </Box>
                </Slide>
                <Waypoint onEnter={() => handleEnterSection(2)}/>
                <Slide
                    direction={'up'}
                    in={appear[ 1 ]}
                    mountOnEnter
                    unmountOnExit
                    container={containerRef.current}
                    {...{ timeout: 1000 }}
                >
                    <Box>
                        <Section
                            photo={secondPhoto}
                            title={'Универсальная система соревнований'}
                            text={'Дает возможность и новичкам, \n' +
                                'и спортсменам с опытом пробовать свои силы в бою \n' +
                                'и применять в действии полученные знания'}
                            imageAlt={'Ребенок во время занятий'}
                            sx={{
                                mt: 2,
                            }}
                        />
                    </Box>
                </Slide>
                <Slide
                    direction={'up'}
                    in={appear[ 2 ]}
                    mountOnEnter
                    unmountOnExit
                    container={containerRef.current}
                    {...{ timeout: 1000 }}
                >
                    <Box>
                        <Section
                            photo={thirdPhoto}
                            photoFirst
                            title={'Уникальная авторская система занятий'}
                            text={'Разработана специально для обучения детей с 3 лет и старше.'}
                            imageAlt={'Во время занятий тхэквондо'}
                            sx={{
                                mt: 2,
                            }}
                        />
                    </Box>
                </Slide>
            </Box>
            <Box
                mt={6}
                width={'100%'}
                height={0}
                pb={'56.25%'}
                position={'relative'}
                border={'3px solid #a22935'}
                borderRadius={0.5}
                sx={{
                    '&:hover': {
                        boxShadow: '12px 12px 51px #c2c2c2, -12px -12px 51px #ffffff;',
                    },
                }}
            >
                <iframe
                    src="https://vk.com/video_ext.php?oid=-155191479&id=456239545&hash=d8cc97271c453791"
                    title={'Нарезка кадров с открытия клуба Вышка'}
                    width="100%"
                    height="100%"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                    frameBorder="0"
                    allowFullScreen="1"
                    allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
                />
            </Box>
            <LeftBackgroundCircle/>
        </Box>
    )
}