import React from 'react'
import { Box } from '@mui/material'
import theme from '../utils/theme'


export const LeftBackgroundCircle = () => (
    <Box
        position={'absolute'}
        borderRadius={'50%'}
        zIndex={-1}
        sx={{
            background: 'rgba(162,41,53,0.18)',
            left: '-50vw',
            top: '10%',
            width: '110vh',
            height: '110vh',
            [ theme.breakpoints.down('md') ]: {
                display: 'none',

            },
        }}
    />
)