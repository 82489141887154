import { Box, Container, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import logo from '../images/badLogo.png'
import React from 'react'
import vkIcon from '../images/vkLogo.png'
import theme from '../utils/theme'
import { allRefs } from '../utils/constants'


const VyshkaLogo = () => {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.only('xs'))

    return (
        <Stack direction={'row'} alignItems={'center'}>
            <Box
                textAlign={'right'}
                mr={1}
            >
                <img
                    src={logo}
                    alt={'logo'}
                    height={isMobile ? 32 : 48}
                    width={'auto'}
                />
            </Box>
            <Box display={'inline'}>
                <Typography
                    fontWeight={300}
                    fontSize={'1rem'}
                    color={'white'}
                    lineHeight={1}
                    whiteSpace={'nowrap'}
                >
                    Спортивный клуб Вышка
                </Typography>
                <Typography
                    color={'white'}
                    fontWeight={200}
                    fontSize={'0.95rem'}
                >
                    город Пермь
                </Typography>
            </Box>
        </Stack>
    )
}

export const VyshkaFooter = () => {

    return (
        <Box py={4} sx={{ background: '#081e2d' }} ref={allRefs.contacts}>
            <Container maxWidth={'lg'}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            color={'white'}
                            fontWeight={500}
                            mb={3}
                        >
                            О нас
                        </Typography>
                        <VyshkaLogo/>
                        <Stack mt={1} direction={'row'} justifyContent={'start'} spacing={1}>
                            {/*<IconButton*/}
                            {/*    sx={{ ml: -1 }}*/}
                            {/*    href={'https://www.instagram.com/club_tkd_vyshka/'}*/}
                            {/*    target="_blank"*/}
                            {/*>*/}
                            {/*    <InstagramIcon fontSize={'large'} sx={{ color: 'white' }}/>*/}
                            {/*</IconButton>*/}
                            <IconButton
                                href={'https://vk.com/clubtkdvyshka'}
                                title={'Мы Вконтакте'}
                                target="_blank"
                            >
                                <img
                                    src={vkIcon}
                                    alt={'Мы Вконтакте'}
                                    height={30}
                                    width={30}
                                />
                            </IconButton>
                        </Stack>
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            color={'#0074C3'}
                            mt={2}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            Политика конфиденциальности
                        </Typography>
                    </Grid>
                    <Grid item
                          xs={12}
                          md={4}
                          sx={{
                              [ theme.breakpoints.down('md') ]: {
                                  mt: 3,
                              },
                          }}
                    >
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            color={'white'}
                            fontWeight={500}
                            mb={3}
                        >
                            Контакты
                        </Typography>
                        <Typography
                            variant={'body1'}
                            color={'white'}
                            mt={1.5}
                            href={'tel://+79504547799'}
                            component={'a'}
                            sx={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                            }}
                        >
                            +7 (950) 454-77-99
                        </Typography>
                        <Box mt={1.5}>
                            <Typography
                                variant={'body1'}
                                color={'white'}
                                component={'a'}
                                href={'mailto:clubtkdvyshka@yandex.ru'}
                            >
                                clubtkdvyshka@yandex.ru
                            </Typography>
                        </Box>
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            color={'white'}
                            mt={1.5}
                        >
                            г. Пермь, ул. Целинная, д.43/1, вход справа, 2 этаж.
                        </Typography>
                    </Grid>
                    <Grid item
                          xs={12}
                          md={4}
                          sx={{
                              [ theme.breakpoints.down('md') ]: {
                                  mt: 3,
                              },
                          }}
                    >
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            color={'white'}
                            fontWeight={500}
                            mb={3}
                        >
                            Время работы
                        </Typography>
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            color={'white'}
                            mt={1.5}
                        >
                            Пн-Сб 9:00 - 22:00
                        </Typography>
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            color={'white'}
                        >
                            Вс 9:00 - 20:00
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}