import { useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { allRefs } from '../../../utils/constants'


const FaqPage = ({ expanded, id, handleChange, question, answer }) => {

    const arrayOfLines = answer.match(/[^\n]+/g)

    return (
        <Accordion
            expanded={expanded === id}
            onChange={handleChange(id)}
            elevation={0}
        >
            <AccordionSummary>
                <Stack direction={'row'} alignItems={'center'}>
                    {
                        expanded === id ?
                            <Typography variant={'h3'} component={'p'} color={'#8D212C'} mr={2}>
                                –
                            </Typography>
                            :
                            <Typography variant={'h3'} component={'p'} color={'#8D212C'} mr={2}>
                                +
                            </Typography>
                    }
                    <Typography
                        variant={'h5'}
                        component={'p'}
                        fontWeight={300}
                        style={{ whiteSpace: 'pre-line' }}
                    >
                        {question}
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    ml: '10%',
                    borderLeft: '2px solid #8D212C',
                }}
            >
                {
                    arrayOfLines.map((line) =>
                        <Typography my={1} key={line.length}>
                            {line}
                        </Typography>,
                    )
                }
            </AccordionDetails>
        </Accordion>
    )
}

export const FAQ = () => {

    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <Box
            textAlign={'start'}
            mt={16}
            ref={allRefs.faq}
        >
            <Typography variant={'h2'} component={'p'}>
                FAQ
            </Typography>
            <Box>
                <FaqPage
                    id={'page1'}
                    expanded={expanded}
                    handleChange={handleChange}
                    question={'Сколько человек в группе?'}
                    answer={'В младшей группе — до 12 человек, в средней — до 18 человек, в старшей группе — до 20 человек. \n' +
                        'Если желающих набирается больше — мы открываем новую группу.'}
                />
                <FaqPage
                    id={'page2'}
                    expanded={expanded}
                    handleChange={handleChange}
                    question={'Сколько стоят занятия?'}
                    answer={'Стоимость занятий зависит от длительности абонемента. \n' +
                        'Абонемент на месяц для новичков стоит 2700 рублей (на момент февраля 2022 года).\n' +
                        'Стоимость одного занятия от 400 рублей.\n'}
                />
                <FaqPage
                    id={'page3'}
                    expanded={expanded}
                    handleChange={handleChange}
                    question={'Что необходимо иметь с собой на первой тренировке?'}
                    answer={'На первое пробное занятие необходимо взять с собой удобную физкультурно-спортивную форму (футболка, шорты или спортивные штаны), а также сменную обувь и отличное настроение!'}
                />
                <FaqPage
                    id={'page4'}
                    expanded={expanded}
                    handleChange={handleChange}
                    question={'Могут ли девочки заниматься тхэквондо?'}
                    answer={'Тхэквондо могут заниматься и мальчики, и девочки. \n' +
                        'Программа позволяет найти интересное направление для каждого и добиться высоких результатов.'}
                />
                <FaqPage
                    id={'page5'}
                    expanded={expanded}
                    handleChange={handleChange}
                    question={'Если ребёнок болеет, будет ли перерасчёт в абонементе?'}
                    answer={'Да, конечно. Перерасчет стоимости абонемента производится в случае пропуска занятий по причине болезни, при предоставлении справки от врача.'}
                />
            </Box>
        </Box>
    )
}