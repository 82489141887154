import { Avatar, Box, Button, Collapse, Grid, Typography, useMediaQuery } from '@mui/material'

import darya from '../../../images/darya.jpg'
import diana from '../../../images/diana.jpg'
import ivan from '../../../images/ivan.jpg'
import React, { useState } from 'react'
import { allRefs } from '../../../utils/constants'


const TrainerCard = ({ name, photo, achievements, videoUrl }) => {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const [open, setOpen] = useState(false)

    const firstAchievements = achievements.slice(0, 4)
    const lastAchievements = achievements.slice(4)

    return (
        <Box
            position={'relative'}
            pt={4}
            pb={isMobile ? 6 : 4}
            pr={3}
            pl={isMobile ? 3 : 6}
            borderRadius={6}
            sx={{
                background: 'rgba(0,74,125,0.12)',
            }}
        >
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item xs={12} md={7}>
                    <Typography variant={'h5'} fontWeight={500} component={'h3'}>
                        {name}
                    </Typography>
                    <Box mt={1.5}
                         sx={{
                             '& :nth-of-type(4)': {
                                 mb: 0,
                             },
                         }}>
                        {
                            firstAchievements.map((achievement) => (
                                    <Grid
                                        container
                                        alignItems={'center'}
                                        key={achievement}
                                        my={1.5}
                                        pl={isMobile ? 0 : 3}
                                        columns={15}
                                        spacing={1}
                                    >
                                        <Grid item xs={1}>
                                            <Box
                                                borderRadius={'50%'}
                                                sx={{
                                                    height: 16,
                                                    width: 16,
                                                    background: '#8D212C',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={14}>
                                            <Typography variant={'h6'} component={'p'} fontWeight={400}>
                                                {achievement}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ),
                            )
                        }
                        {
                            !open && <Grid
                                container
                                alignItems={'center'}
                                key={'expand more'}
                                my={1}
                                pb={1}
                                pl={isMobile ? 0 : 3}
                                columns={15}
                                spacing={1}
                            >
                                <Grid item xs={1}>
                                    <Typography variant={'h4'} component={'p'} fontWeight={800} color={'#8D212C'}
                                                ml={-0.25}>
                                        +
                                    </Typography>
                                </Grid>
                                <Grid item xs={14}>
                                    <Typography
                                        variant={'h6'}
                                        component={'p'}
                                        fontWeight={400}
                                        onClick={() => setOpen((prevState) => !prevState)}
                                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        Еще {achievements.length - 4}
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                        <Collapse in={open} unmountOnExit>
                            {
                                lastAchievements.map((achievement) => (
                                        <Grid
                                            container
                                            alignItems={'center'}
                                            key={achievement}
                                            my={1.5}
                                            pl={isMobile ? 0 : 3}
                                            columns={15}
                                            spacing={1}
                                        >
                                            <Grid item xs={1}>
                                                <Box
                                                    borderRadius={'50%'}
                                                    sx={{
                                                        height: 16,
                                                        width: 16,
                                                        background: '#8D212C',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={14}>
                                                <Typography variant={'h6'} component={'p'} fontWeight={400}>
                                                    {achievement}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ),
                                )
                            }
                        </Collapse>
                    </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Avatar
                        src={photo}
                        alt={`Тренер ${name}`}
                        sx={{
                            width: '80%',
                            height: '100%',
                            mx: 'auto',
                            aspectRatio: 1,
                        }}
                    />
                </Grid>
            </Grid>
            <Button
                variant={'contained'}
                onClick={() => setOpen((prevState) => !prevState)}
                sx={{
                    background: '#8D212C',
                    borderRadius: 10,
                    px: isMobile ? 5 : 10,
                    py: 2,
                    '&:hover': {
                        background: '#a22935',
                    },
                    position: 'absolute',
                    bottom: -30,
                    left: isMobile ? 'unset' : '10%',
                    right: isMobile ? '10%' : 'unset',
                }}
            >
                <Typography variant={'h6'} color={'white'} component={'p'}>
                    {open ? 'Скрыть' : 'Подробнее'}
                </Typography>
            </Button>
            <Collapse in={open} unmountOnExit>
                <Box
                    mt={isMobile ? 4 : 2}
                    mb={isMobile ? 1 : 3}
                    width={'100%'}
                    height={0}
                    pb={'56.25%'}
                    position={'relative'}
                    border={'3px solid #a22935'}
                >
                    <iframe
                        src={videoUrl}
                        title={`Видео интервью тренера ${name}`}
                        width="100%"
                        height="100%"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                        frameBorder="0"
                        allowFullScreen="1"
                        allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
                    />
                </Box>
            </Collapse>

        </Box>
    )
}

export const OurTrainers = () => {

    return (
        <Box textAlign={'start'} mt={12} ref={allRefs.trainers}>
            <Typography variant={'h2'} component={'h2'} mb={4}>
                Наши тренеры
            </Typography>
            <Box mb={8}>
                <TrainerCard
                    name={'Басалгина Дарья Алексеевна'}
                    photo={darya}
                    achievements={[
                        'Руководитель клуба',
                        'Серебряный и Бронзовый призер Чемпионата и Первенства Европы по тхэквондо ГТФ (Швейцария, Давос, 2014)',
                        'Золотой призер Чемпионата и Первенства Мира по тхэквондо ГТФ (Италия, Роана, 2015)',
                        'Бронзовый призер Чемпионата и Первенства Европы по тхэквондо МФТ (Финляндия, Тампере, 2016)',
                        'Золотой призёр Кубка Сибири по тхэквондо ИТФ, женщины (Новосибирск, 2017)',
                        'Бронзовый призёр Чемпионата России по тхэковндо ИТФ, женщины (Сочи, 2018)',
                        'Бронзовый призёр Кубка Мира по тхэквондо ИТФ, женщины (Белоруссия, Минск 2018)',
                    ]}
                    videoUrl={'https://vk.com/video_ext.php?oid=-155191479&id=456239268&hash=37a3d12e65ba4eb6'}
                />
            </Box>
            <Box mb={8}>
                <TrainerCard
                    name={'Маркова Диана Юрьевна'}
                    photo={diana}
                    achievements={[
                        'Победительница Первенства Мира, России',
                        'Призёр Первенства Европы 2019 (Италия г. Римини)',
                        'Победитель Первенства России 2019 (г. Улан-уде)',
                        'Призёр Кубка России 2018 (г. Екатеринбург)',
                        'Победитель Всероссийских юношеских игр (2018 г. Анапа)',
                        'Серебряная призерка Первенства России 2018 (г. Сочи)',
                        'Бронзовая призерка Первенства России 2017 (г. Санкт Петербург)',
                        'Серебряная призерка Кубка России 2017 (г.Екатеренбург)',
                        'Победитель Первенства Мира 2016 по спаррингам (г.Казань)',
                    ]}
                    videoUrl={'https://vk.com/video_ext.php?oid=-155191479&id=456239558&hash=d4380c76cdee434b'}
                />
            </Box>

            <Box mb={8}>
                <TrainerCard
                    name={'Шишкин Иван Дмитриевич'}
                    photo={ivan}
                    achievements={[
                        'Многократный чемпион и призер Пермского края',
                        'Серебряный призер всероссийских юношеских игр (Анапа 2015)',
                        'Многократный чемпион и призер Первенства России',
                        'Бронзовый призер Кубка России (2017)',
                        'Многократный победитель в командных соревнованиях различного уровня',
                        'Победитель и призер межрегеонального турнира "Открытие"',
                    ]}
                    videoUrl={'https://vk.com/video_ext.php?oid=-155191479&id=456239521&hash=cdceced1daeb64f8'}
                />
            </Box>

        </Box>
    )
}