import React, { useState } from 'react'
import {
    AppBar,
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@mui/material'
import theme from '../utils/theme'

import logo from '../images/logo.jpg'
import MenuIcon from '@mui/icons-material/Menu'
import { useLocation, useNavigate } from 'react-router'
import { allRefs } from '../utils/constants'


const VyshkaLogo = () => {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.only('xs'))
    const navigate = useNavigate()

    return (
        <Stack direction={'row'} alignItems={'center'}>
            <Box
                textAlign={'right'}
                mr={1}
                onClick={() => navigate('/')}
                sx={{
                    cursor: 'pointer',
                }}
            >
                <img
                    src={logo}
                    alt={'Логотип клуба Вышка'}
                    height={isMobile ? 48 : 64}
                    width={'auto'}
                />
            </Box>
            <Box display={'inline'}>
                <Typography
                    variant={'h6'}
                    component={'p'}
                    whiteSpace={'nowrap'}
                    lineHeight={1}
                    sx={{
                        [ theme.breakpoints.only('xs') ]: {
                            fontSize: '1rem',
                        },
                    }}
                >
                    Спортивный клуб Вышка
                </Typography>
                <Typography
                    variant={'subtitle1'}
                    component={'p'}
                    sx={{
                        [ theme.breakpoints.only('xs') ]: {
                            fontSize: '0.9rem',
                        },
                    }}
                >
                    город Пермь
                </Typography>
            </Box>
        </Stack>
    )
}

const VyshkaAppBar = () => {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const sections = [
        {
            name: 'О клубе',
            ref: allRefs.aboutUs,
            link: '/',
        },
        {
            name: 'FAQ',
            ref: allRefs.faq,
            link: '/',
        },
        {
            name: 'Найти нас',
            ref: allRefs.map,
            link: '/',
        },
        {
            name: 'Тренеры',
            ref: allRefs.trainers,
            link: '/',
        },
        {
            name: 'Отзывы',
            ref: allRefs.reviews,
            link: '/',
        },
        {
            name: 'Контакты',
            ref: allRefs.contacts,
            link: '/',
        },
        {
            name: 'Галерея',
            ref: 'gallery',
            link: '/gallery',
        },
    ]

    const navigate = useNavigate()
    const location = useLocation()

    const [anchorElNav, setAnchorElNav] = useState(null)
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = (section) => {
        setAnchorElNav(null)
        if (section === 'close') return
        setTimeout(() => {
            if (location.pathname === '/') {
                if (section.ref !== 'gallery') {
                    section.ref.current.scrollIntoView({
                        behavior: 'smooth',
                    })
                } else {
                    navigate('/gallery')
                }
            } else {
                if (section.ref) {
                    navigate('/')
                }
            }
        }, 100)
    }

    return (
        <AppBar
            position="static"
            elevation={0}
            sx={{
                maxHeight: 64,
                background: 'transparent',
                py: 1,
            }}
        >
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item>
                            <VyshkaLogo/>
                        </Grid>
                        <Grid item>
                            <Typography
                                display={'inline'}
                                mr={1.5}
                                href={'tel://+79504547799'}
                                component={'a'}
                                sx={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    [ theme.breakpoints.down('md') ]: {
                                        display: 'none',
                                    },
                                }}
                            >
                                +7 (950) 454-77-99
                            </Typography>
                            <Button
                                variant={'contained'}
                                href={'https://wa.me/79504547799'}
                                target="_blank"
                                sx={{
                                    background: '#8D212C',
                                    borderRadius: 10,
                                    mb: 1,
                                    px: 3,
                                    '&:hover': {
                                        background: '#a22935',
                                    },
                                    [ theme.breakpoints.down('md') ]: {
                                        display: 'none',
                                    },
                                }}
                            >
                                Записаться
                            </Button>
                            {
                                isMobile &&
                                <Box>
                                    <IconButton
                                        size="large"
                                        aria-label="menu-mobile"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                    >
                                        <MenuIcon sx={{ color: '#8D212C' }}/>
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={() => handleCloseNavMenu('close')}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                            '& .MuiMenu-list': {
                                                border: '1px solid #8D212C',
                                            },
                                        }}
                                    >
                                        {
                                            sections.map((page) => (
                                                <MenuItem
                                                    key={page.name}
                                                    onClick={() => handleCloseNavMenu(page)}
                                                >
                                                    <Typography textAlign="center">
                                                        {page.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))
                                        }
                                    </Menu>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
            {
                !isMobile &&
                <Container maxWidth="lg" sx={{
                    '& .MuiToolbar-root': {
                        minHeight: 40,
                    },
                }}>
                    <Toolbar disableGutters>
                        <Grid
                            container
                            direction={'row'}
                            justifyContent={'space-evenly'}
                            alignItems={'center'}
                        >
                            {
                                sections.map((section) => (
                                    <Grid item key={section.name}>
                                        <Typography
                                            variant={'subtitle1'}
                                            component={'p'}
                                            color={'#004A7D'}
                                            fontWeight={400}
                                            sx={{
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                },
                                            }}
                                            onClick={() => {
                                                handleCloseNavMenu(section)
                                            }}
                                        >
                                            {section.name}
                                        </Typography>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Toolbar>
                </Container>
            }
        </AppBar>
    )
}

export default VyshkaAppBar
