import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import photo from '../../../images/photoTheySay.jpg'
import { allRefs } from '../../../utils/constants'


export const TheyTalk = () => {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

    return (
        <Box
            textAlign={'start'}
            mt={12}
            py={3}
            position={'relative'}
            ref={allRefs.reviews}
        >
            <Box
                position={'absolute'}
                top={0}
                left={'-50vw'}
                width={'200vw'}
                height={'100%'}
                zIndex={-1}
                sx={{
                    background: 'rgba(0,74,125,0.12)',
                }}
            />
            <Typography variant={'h2'} component={'p'}>
                О нас говорят
            </Typography>
            <Grid
                container
                direction={isMobile ? 'column-reverse' : 'row'}
                mt={isMobile ? 4 : 1}
                spacing={isMobile ? 0 : 4}
            >
                <Grid item xs={12} md={6} mt={isMobile ? 2 : 0}>
                    <Box
                        width={'100%'}
                        height={'75vh'}
                        position={'relative'}
                        overflow={'hidden'}
                    >
                        <iframe
                            // style="width:100%;height:100%;border:1px solid #e6e6e6;border-radius:8px;box-sizing:border-box"
                            title={'Отзывы о клубе Вышка на Яндекс.Картах'}
                            style={{
                                width: '100%',
                                height: '100%',
                                boxSizing: 'border-box',
                                border: 'none',
                                overflow: 'scroll',
                                '& .badge': {
                                    background: 'red',
                                },
                            }}
                            src="https://yandex.ru/maps-reviews-widget/150526195124?comments"/>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    {isMobile ?
                        <Box
                            height={'27vh'}
                            width={'100%'}
                            borderRadius={1}
                            title={'Ребенок во время занятий'}
                            sx={{
                                backgroundImage: `url(${photo})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                            }}
                        />
                        :
                        <Box
                            height={'100%'}
                            width={'100%'}
                            borderRadius={1}
                            title={'Ребенок во время занятий'}
                            sx={{
                                backgroundImage: `url(${photo})`,
                                backgroundSize: 'cover',
                                backgroundPosition: '60% center',
                                backgroundRepeat: 'no-repeat',
                            }}
                        />
                    }
                </Grid>
            </Grid>
        </Box>
    )
}