import React from 'react'
import { Box, Button, Grid, Slide, Stack, Typography, useMediaQuery } from '@mui/material'
import theme from '../../../utils/theme'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import video from '../../../images/video.mp4'


const ToBe = ({ text, index }) => {
    return (
        <Slide in={true} {...{ timeout: 400 * index }} direction={'up'}>
            <Stack
                direction={'row'}
                alignItems={'center'}
                textAlign={'start'}
                py={1}
            >
                <CheckCircleRoundedIcon fontSize={'large'} sx={{ color: '#004A7D', mr: 1 }}/>
                <Typography variant={'h5'} fontWeight={300} component={'p'}>
                    {text}
                </Typography>
            </Stack>
        </Slide>
    )
}

export const Introduction = () => {

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

    return (
        <>
            <Box
                minHeight={'80vh'}
                sx={{
                    [ theme.breakpoints.down('md') ]: {
                        textAlign: 'center',
                    },
                }}
            >
                <Grid
                    container
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Grid item md={7} xs={12}>
                        <Typography
                            variant={'h2'}
                            component={'h1'}
                            fontWeight={300}
                            sx={{
                                width: isMobile ? '100%' : '120%',
                            }}
                        >
                            Добро пожаловать в команду победителей!
                        </Typography>
                        <Button
                            variant={'contained'}
                            sx={{
                                background: '#8D212C',
                                borderRadius: 10,
                                mt: 8,
                                px: 6,
                                py: 2,
                                '&:hover': {
                                    background: '#a22935',
                                },
                            }}
                            href={'https://wa.me/79504547799'}
                            target="_blank"
                        >
                            <Typography variant={'h4'} component={'p'} color={'white'}>
                                Записаться
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item md={5} sm={false}>
                        <Box
                            sx={{
                                [ theme.breakpoints.down('md') ]: {
                                    display: 'none',
                                },
                            }}
                        >
                            <video
                                src={video}
                                autoPlay
                                muted
                                loop
                                controls={false}
                                title={'Жизнь в клубе тхэквонддо Вышка'}
                                style={{
                                    // position: 'absolute',
                                    // left: '35%',
                                    // top: 0,
                                    transform: 'translate(20%, 10%) scale(1.6)',
                                    width: '100%',
                                    clipPath: 'circle(34% at 50% 50%)',
                                    overflow: 'hidden',
                                    filter: 'opacity(80%)',
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Typography
                    variant={'h4'}
                    component={'h2'}
                    mt={isMobile ? 8 : 8}
                >
                    Вступай в клуб, если хочешь:
                </Typography>
                <ToBe index={1} text={'Сделать ребенка здоровым, умелым, сообразительным, успешным'}/>
                <ToBe index={2} text={'Развить у него выносливость, координацию, силу, память'}/>
                <ToBe index={3} text={'Научить дисциплине, дружбе, самоорганизации'}/>
                <ToBe index={4} text={'Вырастить чемпиона'}/>
            </Box>
        </>
    )
}