import { Box, Typography } from '@mui/material'
import theme from '../../../utils/theme'
import React from 'react'
import { allRefs } from '../../../utils/constants'


const BG = () => {
    return (
        <Box
            position={'absolute'}
            borderRadius={'50%'}
            zIndex={-1}
            sx={{
                background: 'rgba(0,74,125,0.12)',
                right: '-50vw',
                top: '-25vh',
                width: '110vh',
                height: '110vh',
                [ theme.breakpoints.down('md') ]: {
                    display: 'none',
                },
            }}
        />
    )
}

export const VyshkaMap = () => {

    return (
        <Box textAlign={'start'} mt={12} ref={allRefs.map} position={'relative'}>
            <BG/>
            <Typography variant={'h2'} component={'p'}>
                Мы на карте
            </Typography>
            <Box
                mt={4}
                width={'100%'}
                height={'60vh'}
            >
                <iframe
                    src={'https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=150526195124'}
                    title={'yandex-map'}
                    width={'100%'}
                    height={'100%'}
                    frameBorder={'0'}
                    style={{
                        borderRadius: 6,
                    }}
                />
            </Box>
        </Box>
    )
}